import axios from 'axios'

let instance;
let handle403Callback;

export default {
  init: (config, callback) => {
    instance = axios.create(config)

    instance.interceptors.request.use(config => {
      config.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
      handle403Callback = callback
      return config;
    });

    instance.interceptors.response.use(response => {
      return response;
    }, error => {
      // keycloak with PolicyEnforcer and UMA will only return status 403 for unauth and unauthz
      if (error.response) { 
        if (error.response.status === 403) {
          // forbidden might be expected to handle without login redirect if we have some out of 
          // access features.
          if (handle403Callback != null) {
            handle403Callback();
          }
          return Promise.reject(error);
        }
      } else {
        console.log('Axios internal error', error)
      }
      return Promise.reject(error);
    });
  },

  
  addAccount: (accountName, account)  => instance.post('accounts/' + accountName + '/createAccount', account),
  addSystemCurrency: (currencyCode) => instance.post('systemCurrencies/' + currencyCode),
  addPartner: (partner) => instance.post('partners', partner),
  addCustomer: (customer) => instance.post('customer', customer),
  addCustomerAccountNote: (customerCodeId, customerAccountId, note) => instance.post('customer/' + customerCodeId + '/account/' + customerAccountId + '/note', note),
  addCustomerNote: (customerCodeId, note) => instance.post('customer/' + customerCodeId + '/note', note),
  addCustomerContact: (customerCodeId,contact) => instance.post('customer/' + customerCodeId + '/contact', contact),
  addCustomerLedger: (customerCodeId,ledger) => instance.post('customer/' + customerCodeId + '/ledger', ledger),
  addMultiplier: (customerAccountId, params) => instance.post('customerAccount/' + customerAccountId + '/multiplier', params),
  addSettlementTerms: (params) => instance.post('settlementTerm', params),
  addTransactionFee: (transFee) => instance.post('transactionFee', transFee),
  closeLedgerDate: (date) => instance.post('accounting/closeLedgerDate', date),
  deleteCustomerAccountNote: (customerCodeId, customerAccountId, customerNoteId) => instance.delete('customer/' + customerCodeId + '/account/'+ customerAccountId + '/note/' + customerNoteId ),
  deleteCustomerNote: (customerCodeId, customerNoteId) => instance.delete('customer/' + customerCodeId + '/note/' + customerNoteId ),
  deleteCustomerContact: (customerCodeId, customerContactId) => instance.delete('customer/' + customerCodeId + '/contact/' + customerContactId ),
  deleteCustomerLedger: (customerLedgerId) => instance.delete('ledger/' + customerLedgerId),
  deleteJobExecution: (jobExecutionId) => instance.delete('system/jobs/execution/' + jobExecutionId),
  deleteJobInstance: (jobInstanceId) => instance.delete('system/jobs/instance/' + jobInstanceId),
  deleteTransaction: (transId) => instance.delete('ledgerTransaction/' + transId),
  deleteTransactionFee: (transFeeId) => instance.delete('transactionFee/' + transFeeId),
  disableSystemCurrency: (currencyCode) => instance.put('systemCurrencies/'+ currencyCode + "/disable"),  
  getAccounts: () => instance.get('accounts?size=1000'),
  getAccountActivity: (params) => instance.get('reports/accountActivity', {params: params}),
  getAccountActivitySummary: (accountName, params) => instance.get('accounts/' + accountName + '/activitySummary', {params: params}),
  getAccountCheckUser: (accountName,userCode) => instance.get('accounts/' + accountName + '/checkUser/' + userCode),
  getAccountsTree: (params) => instance.get('accounts/tree', {params: params}),
  getAccountStatement: (accountName, params) => instance.get('accounts/' + accountName + '/statement', { params: params}),
  getAccount: (accountName) => instance.get('accounts/'+accountName),
  getAccountingStatus: () => instance.get('accounting/status'),
  getAccountTransferLimits: (accountName) => instance.get('accounts/' + accountName + '/transferLimits'),
  getAccountingEvents: (params) => instance.get('accounting/events', { params: params }),
  getBets: (accountName, params) => instance.get('bets/' + accountName , {params: params }),
  getAgentTemplate: (accountName) => instance.get('accounts/' + accountName + '/agentTemplate'),
  getBetSports: () => instance.get('betevent/sports'),
  getCashierCurrencies: () => instance.get('cashier/currencies'),
  getCreditGiven: (params) => instance.get('reports/creditGiven', {params: params}),
  getCreditLimits: (params) => instance.get('accounts/creditLimits', {params: params}),
  getCustomer: (customerCodeId, params) => instance.get('customer/' + customerCodeId, {params: params}),
  getCustomers: (params) => instance.get('customer?size=10000', {params: params}),
  getCustomerAccountNotes: (customerCodeId, customerAccountId, params) => instance.get('customer/' + customerCodeId + '/account/' + customerAccountId + '/note', {params: params}),
  getCustomerNotes: (customerCodeId,params) => instance.get('customer/' + customerCodeId + '/note',{params: params}),
  getCustomerContacts: (customerCodeId,params) => instance.get('customer/' + customerCodeId + '/contact', {params: params}),
  getCustomerLedgers: (customerCodeId) => instance.get('customer/' + customerCodeId + '/ledger'),
  getCustomerLedgerList: (customerCodeId,params) => instance.get('customer/' + customerCodeId + '/ledgerList', {params: params}),
  getCustomerList: () => instance.get('customer/list'),
  getCustomerSummary: () => instance.get('customer/summary'),
  getCurrencies: () => instance.get('currencies?size=1000&sort=name,asc'),
  getCurrencyHistoricRates: (params) => instance.get('rates/search/byCodeAndDate', { params: params} ),
  getCustomerCashBalances: (params) => instance.get('reports/customerCashBalances', {params: params}),
  getCustomerLedgerBalances: (params) => instance.get('reports/customerLedgerBalances', {params: params}),
  getCustomerNetBalances: (params) => instance.get('reports/customerNetBalances', {params: params}),
  getDailyReconciliationList: (params) => instance.get('reconciliation', {params: params}),
  getDailyReconciliationDetail: (params) => instance.get('reconciliation/detail', {params: params}),
  getDailyReconciliationCL: (params) => instance.get('clReconciliation', {params: params}),
  getDailyReconciliationCLDetail: (params) => instance.get('clReconciliation/detail', {params: params}),
  getDailySummary: (params) => instance.get('reports/dailySummary', {params: params}),
  getEventData: () => instance.get('betevent/events'),
  getJobs: () => instance.get('system/jobs'),
  getJobInstances: (jobName) => instance.get('system/jobs/' + jobName),
  getJobInstance: (jobInstanceId) => instance.get('system/jobs/instance/' + jobInstanceId),
  getJournalEntryCb: (params) => instance.get('cbJournal/journalEntry', {params: params}),
  getJournalEntryCl: (params) => instance.get('clJournal/journalEntry', {params: params}),
  getJournalEntrySummaryCb: (params) => instance.get('cbJournal/list', {params: params}),
  getJournalEntrySummaryCl: (params) => instance.get('clJournal/list', {params: params}),
  getLedgers: (params) => instance.get('ledger', {params: params}),
  getLedgerAccounts: (ledgerId) => instance.get('ledger/' + ledgerId + '/accounts'),
  getLedgerDetail: (ledgerId) => instance.get('ledger/' + ledgerId),
  getLedgerStatus: () => instance.get('accounting/ledgerStatus'),
  getLedgerTransactions: (ledgerId, params) => instance.get('ledger/' + ledgerId + '/statement', {params: params}),
  getAllLedgerTransactions: (params) => instance.get('ledgerTransaction', {params: params}),
  getLedgerTransfer: (ledgerTransactionId) => instance.get('ledgerTransaction/' + ledgerTransactionId + '/transferDetail'),
  getLedgerPositionStatement: (ledgerId,params) => instance.get('/ledger/' + ledgerId + '/positionStatement', {params: params}),
  getLinkableAccounts: (params) => instance.get('accounts/linkable', {params: params}),
  getMultiplier: (customerAccountId) => instance.get('customerAccount/' + customerAccountId + "/multiplier"),
  getMultiplierBetTypes: () => instance.get('multiplierBetType'),
  getMultiplierLeagues: () => instance.get('multiplierLeague'),
  getMsAccountBalances: (accountName) => instance.get('accounts/' + accountName + '/msBalances'),
  getOrgAccountStatement: (params) => instance.get('reports/orgAccountStatement', { params: params}),
  getOrgNetResults: (params) => instance.get('reports/orgNetResults', {params: params}),
  getPartners: () => instance.get('partners?size=1000'),
  getPartnerBalances: (params) => instance.get('reports/partnerBalances', {params: params}),
  getPartnerNetResults: (params) => instance.get('reports/partnerNetResults', {params: params}),
  getPositions: (params) => instance.get('position', {params: params}),
  getSearchResults: (params) => instance.get('search', {params: params}),
  getPosStmtJobs: (params) => instance.get('batch/positionStatement', {params: params}),
  getPosStmtJobDetail: (batchUuid, params) => instance.get('batch/positionStatement/' + batchUuid, {params: params}),
  getRate: (params) => instance.get('cashier/rate', {params: params}),
  getSettlementTerms: () => instance.get('settlementTerm'),
  getSevenDayActivity: (params) => instance.get('reports/sevenDayActivity', {params: params}), 
  getScheduledJobs: () => instance.get('system/scheduler'),
  getSystemCurrencies: () => instance.get('systemCurrencies?size=100'),
  getSystemTime: () => instance.get('status/time'),
  getTransactionFee: (params) => instance.get('cashier/fee', {params: params}),
  getTransactionFees: () => instance.get('transactionFee'),
  getUpcomingBetEvents: () => instance.get('betevent/events/upcoming'),
  getWDWN: (params) => instance.get('wdwn/spreadsheet', {params: params }),
  getWinLossDetail: (params) => instance.get('reports/winLossDetail', {params: params}),
  generateBetStatement: (accountName, params) => instance.put('bets/' + accountName, null, { params: params }),
  ledgerCashBalanceTransf: (payload) => instance.post('cashier/cashBalanceTransfer', payload),
  ledgerFundsReceived: (payload) => instance.post('cashier/fundsReceived', payload),
  ledgerPaymentOut: (payload) => instance.post('cashier/paymentOut', payload),
  ledgerTransaction: (payload) => instance.post('cashier/transaction', payload),
  ledgerTransfer: (payload) => instance.post('cashier/ledgerTransfer', payload),
  reloadBets: (payload) => instance.post('system/bets/reload', payload),
  rollbackAccounting: () => instance.post('accounting/rollbackDay'),
  linkAccount: (customerCodeId, payload) => instance.post('customer/' + customerCodeId + '/link', payload),
  restartJobExecution: (jobExecutionId) => instance.post('system/jobs/execution/' + jobExecutionId +' /restart'),
  failJobExecution: (jobExecutionId) => instance.post('system/jobs/execution/' + jobExecutionId +' /fail'),
  sendLedgerPositionStatement: (ledgerId,params) => instance.post('/ledger/' + ledgerId + '/positionStatement', null, {params: params}),
  sendPositionStatementBatch: (payload) => instance.post('batch/positionStatement', payload),
  startEndOfDayJob: () => instance.post('accounting/endOfDayJob'),
  updateCustomer: (customerCodeId, customer) => instance.put('customer/' + customerCodeId, customer),
  updateCustomerAccount: (customerCodeId, customerAccountId, account) => instance.put('customer/' + customerCodeId + '/account/' + customerAccountId, account),
  updateCustomerAccountNote: (customerCodeId, customerAccountId, customerAccountNoteId, note) => instance.put('customer/' + customerCodeId + '/account/' + customerAccountId + "/note/" + customerAccountNoteId, note),
  updateCustomerNote: (customerCodeId, customerNoteId, note) => instance.put('customer/' + customerCodeId + '/note/' + customerNoteId, note),
  updateCustomerContact: (customerCodeId, customerContactId, contact) => instance.put('customer/' + customerCodeId + '/contact/' + customerContactId, contact),
  updateCustomerAutoTransFee: (payload) => instance.put('customer/autoTransFee', payload),
  updateLedger: (ledgerId, ledger) => instance.put('ledger/' + ledgerId, ledger),
  updateMultiplier: (customerAccountId, data) => instance.put('customerAccount/' + customerAccountId + '/multiplier', data),
  updatePartnerCredentials: (partner) => instance.post('partners/credentials', partner),
  updateTransaction: (transId, payload) => instance.put('ledgerTransaction/' + transId, payload),
  updateTransactionFee: (transFeeId, payload) => instance.put('transactionFee/' + transFeeId, payload),
  calcFxConversion: (conversion) => instance.post('cashier/calcFx', conversion),
}