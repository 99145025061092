import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import defineAbilityFor from '../config/ability'
import store from '@/store'
import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/unauthorized',
        name: 'unauthorized',
        component: () => import(/* webpackChunkName: "unauthorized" */ '@/views/Unauthorized.vue'), 
      },
      {
        path: '/accounts',
        name: 'accounts',
        component: () => import(/* webpackChunkName: "accounts" */ '@/views/accounts/Accounts.vue')
      },
      {
        path: '/account/:accountName/CreateAccount',
        name: 'create-account',
        meta: { subject: 'create_sub_account'},
        component: () => import(/* webpackChunkName: "create-account" */ '@/views/accounts/CreateAccount.vue')            
      },
      {
        path: '/account/:accountName',
        name: 'account',
        component: () => import(/* webpackChunkName: "account" */ '@/views/accounts/Account.vue'),
        redirect: { name: 'account-details' },
        children: [ 
          {
            path: 'details',
            name: 'account-details',
            component: () => import(/* webpackChunkName: "account-details" */ '@/views/accounts/AccountDetails.vue')
          },
          {
            path: 'CashBalance',
            name: 'account-cash-balance',
            component: () => import(/* webpackChunkName: "account-cash-balance" */ '@/views/accounts/AccountCashBalance.vue')
          },
          {
            path: 'Bets',
            name: 'account-bets',
            component: () => import(/* webpackChunkName: "account-bets" */ '@/views/accounts/AccountBets.vue')
          },
          {
            path: 'SystemBalances',
            name: 'account-system-balances',
            component: () => import(/* webpackChunkName: "account-system-balances" */ '@/views/accounts/SystemBalances.vue'),
          },
          {
            path: 'ManagerSite',
            name: 'account-ms',
            component: () => import(/* webpackChunkName: "account-ms" */ '@/views/accounts/ManagerSite.vue')
          },
        ],
      },
      {
        path: '/customers',
        name: 'customers',
        component: () => import(/* webpackChunkName: "customers" */ '@/views/customers/Customers.vue')
      },
      {
        path: '/customer/:customerCodeId',
        name: 'customer',
        component: () => import(/* webpackChunkName: "customer" */ '@/views/customers/Customer.vue')
      },
      {
        path: '/ledgers',
        name: 'ledgers',
        component: () => import(/* webpackChunkName: "ledgers" */ '@/views/ledgers/Ledgers.vue')
      },
      {
        path: '/positions',
        name: 'positions',
        component: () => import(/* webpackChunkName: "positions" */ '@/views/ledgers/Positions.vue')
      },
      {
        path: '/ledger/:ledgerId',
        name: 'ledger-detail',
        component: () => import(/* webpackChunkName: "ledger-detail" */ '@/views/ledgers/Ledger.vue')
      },
      {
        path: '/ledger/:ledgerId/statement',
        name: 'ledger-statement',
        component: () => import(/* webpackChunkName: "ledger-statement" */ '@/views/ledgers/LedgerPosition.vue')
      },
      {
        path: '/accounting',
        redirect: '/accounting/DailyJournalCB',
        name: 'Accounting',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          }
        },
        children: [
          {
            path: 'DailyJournalCB',
            name: 'daily-journal-cb',
            redirect: { name: 'daily-journal-cb-list' },
            component: {
              render() {
                return h(resolveComponent('router-view'))
              },
            },
            children: [ 
              {
                path: 'entries',
                name: 'daily-journal-cb-list',
                component: () => import(/* webpackChunkName: "daily-journal-cb" */ '@/views/accounting/DailyJournalCB.vue')
              },
              {
                path: 'entry/:date',
                name: 'daily-journal-cb-entry',
                component: () => import(/* webpackChunkName: "daily-journal-cb-entry" */ '@/views/accounting/DailyJournalCBEntry.vue')
              },
            ]
          },
          {
            path: 'DailyReconciliation',
            name: 'Daily Reconciliation',
            redirect: {name: 'daily-reconciliation-list'},
            component: {
              render() {
                return h(resolveComponent('router-view'))
              }
            },
            children: [
              {
                path: 'list',
                name: 'daily-reconciliation-list',
                component: () => import(/* webpackChunkName: "daily-reconciliation-list" */ '@/views/accounting/DailyRecCB.vue'),
              },
              {
                path: 'detail/:date',
                name: 'daily-reconciliation-detail',
                component: () => import(/* webpackChunkName: "daily-reconciliation-detail" */ '@/views/accounting/DailyRecCBDetail.vue'),
              }
            ]
          },
          {
            path: 'AccountingStatusLog',
            name: 'accounting-events',
            component: () => import(/* webpackChunkName: "accounting-events" */ '@/views/accounting/AccountingStatusLog.vue')
          },
        ]
      },
      {
        path: '/reports',
        redirect: 'reports/DailySummary',
        name: 'Reports',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: 'SystemLIVE',
            name: 'system-live',
            component: () => import(/* webpackChunkName: "system-live" */ '@/views/reports/SystemLIVE.vue')
          },
          {
            path: 'AccountActivity/:customerCodeId?',
            name: 'account-activity',
            component: () => import(/* webpackChunkName: "account-activity" */ '@/views/reports/AccountActivity.vue')
          },
          {
            path: 'SevenDayActivity',
            name: 'seven-day-activity',
            component: () => import(/* webpackChunkName: "seven-day-activity" */ '@/views/reports/SevenDayActivity.vue')
          },
          {
            path: 'C5',
            name: 'c5-account',
            component: () => import(/* webpackChunkName: "c5-account" */ '@/views/reports/C5Account.vue'),
            redirect: { name: 'c5-cash-balance' },
            children: [ 
            {
              path: 'CashBalance',
              name: 'c5-cash-balance',
              component: () => import(/* webpackChunkName: "c5-cash-balance" */ '@/views/accounts/AccountCashBalance.vue')
            },
            ]
          },
          {
            path: 'CreditGiven',
            name: 'credit-given',
            component: () => import(/* webpackChunkName: "credit-given" */ '@/views/reports/CreditGiven.vue'),
            redirect: { name: 'credit-given-table' },
            children: [ 
              {
                path: 'table',
                name: 'credit-given-table',
                component: () => import(/* webpackChunkName: "credit-given-table" */ '@/views/reports/CreditGivenTable.vue')
              },
              {
                path: 'graph',
                name: 'credit-given-graph',
                component: () => import(/* webpackChunkName: "credit-given-graph" */ '@/views/reports/CreditGivenGraph.vue')
              },
            ]
          },
          {
            path: 'C5PPR',
            name: 'c5-ppr',
            component: () => import(/* webpackChunkName: "c5-ppr" */ '@/views/reports/C5PPR.vue')
          },
          {
            path: 'PartnerActivity',
            name: 'partner-activity',
            component: () => import(/* webpackChunkName: "partner-activity" */ '@/views/reports/PartnerActivity.vue'),
            redirect: { name: 'partner-activity-detail' },
            children: [
              {
                path: 'detail',
                name: 'partner-activity-detail',
                component: () => import(/* webpackChunkName: "partner-activity-detail" */ '@/views/reports/PartnerActivityDetail.vue')
              }
            ]
          },
          {
            path: 'CustomerBalances',
            name: 'Customer-balances',
            component: () => import(/* webpackChunkName: "customer-balances" */ '@/views/reports/CustomerBalances.vue')
          },
          
          {
            path: 'WDWN',
            name: 'wdwn',
            component: () => import(/* webpackChunkName: "wdwn" */ '@/views/reports/WDWN.vue')
          },
        ]
      },
      {
        path: '/system/email/customerStatement',
        name: 'system-email-statement',
        component: () => import(/* webpackChunkName: "system-email-stmt-jobs" */ '@/views/system/email/PosStmtJobs.vue')
      },
      {
        path: '/system/email/customerStatement/:uuid',
        name: 'system-email-statement-detail',
        component: () => import(/* webpackChunkName: "system-email-stmt-job-detail" */ '@/views/system/email/PosStmtJobDetail.vue')
      },
      /*
      {
        path: 'system/scheduledJobs',
        name: 'system-scheduled-jobs',
        component: () => import(/* webpackChunkName: "system-scheduled-jobs" * / '@/views/system/ScheduledJobs.vue')
      },
      */

      {
        path: 'system/recovery',
        name: 'system-recovery',
        component: () => import(/* webpackChunkName: "system-recovery" */ '@/views/system/Recovery.vue')
      },
      {
        path: '/system/jobs',
        redirect: '/system/jobs/list',
        meta: { subject: 'roma_settings'},
        component: () => import(/* webpackChunkName: "jobs" */ '@/views/system/jobs/JobsTemplate.vue'),
        children: [
          {
            path: 'list',
            name: 'jobs-list',
            meta: { subject: 'roma_settings'},
            component: () => import(/* webpackChunkName: "jobs-list" */ '@/views/system/jobs/JobsList.vue')
          },
          {
            path: 'instances/:jobName',
            name: 'job-instances',
            meta: { subject: 'roma_settings'},
            component: () => import(/* webpackChunkName: "job-instances" */ '@/views/system/jobs/JobInstances.vue')
          },
          {
            path: 'executions/:jobInstanceId',
            name: 'job-executions',
            meta: { subject: 'roma_settings'},
            component: () => import(/* webpackChunkName: "job-executions" */ '@/views/system/jobs/JobExecutions.vue')
          },
        ]
      },
      {
        path: '/settings/currencies',
        name: 'Currencies',
        meta: { subject: 'roma_config'},
        component: () => import(/* webpackChunkName: "currencies" */ '@/views/settings/Currencies.vue'),
      },
      {
        path: '/settings/partners',
        name: 'Partners',
        meta: { subject: 'roma_config'},
        component: () => import(/* webpackChunkName: "partners" */ '@/views/settings/Partners.vue')
      },
      {
        path: '/settings/transactionFees',
        name: 'transaction-fees',
        meta: { subject: 'roma_config' },
        component: () => import(/* webpackChunkName: "transaction-fees" */ '@/views/settings/TransactionFees.vue')
      },
      {
        path: '/settings/settlementTerms',
        name: 'settlement-terms',
        meta: { subject: 'roma_config' },
        component: () => import(/* webpackChunkName: "settlement-terms" */ '@/views/settings/SettlementTerms.vue')
      },
      {
        path: 'cashier',
        name: 'cashier',
        component: () => import(/* webpackChunkName: "cashier" */ '@/views/cashier/Cashier.vue')
      },
      {
        path: 'ledgersDate',
        name: 'ledgers-date',
        component: () => import(/* webpackChunkName: "ledgers-date" */ '@/components/cashier/LedgersDate.vue')
      },
      {
        path: 'transactions',
        name: 'ledger-transactions',
        component: () => import(/* webpackChunkName: "ledger-transactions" */ '@/views/ledgers/Transactions.vue')
      },
      {        
        path: 'dailyReconciliationCL',
        name: 'daily-rec-cl',
        component: () => import(/* webpackChunkName: "daily-rec-cl" */ '@/views/ledgers/DailyRecCL.vue')
      },
      {        
        path: 'dailyReconciliationCL/detail/:date',
        name: 'daily-rec-cl-detail',
        component: () => import(/* webpackChunkName: "daily-rec-cl-detail" */ '@/views/ledgers/DailyRecCLDetail.vue')
      },
      {        
        path: 'dailyJournalCL/',
        name: 'daily-journal-cl',
        component: () => import(/* webpackChunkName: "daily-journal-cl" */ '@/views/ledgers/DailyJournalCL.vue')
      },
      {        
        path: 'dailyJournalCL/:date',
        name: 'daily-journal-entry-cl',
        component: () => import(/* webpackChunkName: "daily-journal-entry-cl" */ '@/views/ledgers/DailyJournalEntryCL.vue')
      },
      {
        path: 'fx',
        redirect: 'fx/rates',
        name: 'FX',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        children: [
          {
            path: 'rates',
            name: 'rates',
            component: () => import(/* webpackChunkName: "fx-rates" */ '@/views/fx/FxRates.vue')
          },
        ]
      },
      {
        path: '/logout',
        name: 'logout',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
          beforeRouteEnter(to, from, next) {
  
            next(vm => {
              const SKIP_RESET_MODULES = ['user', 'nav']
  
              const moduleNames = Object.keys(store._modules.root._children)
              moduleNames.forEach(m => {
                if (SKIP_RESET_MODULES.includes(m)) return // don't reset some modules
                store.commit(m + '/resetState')
              })

              store.commit('user/logout')
              localStorage.removeItem("token")
              vm.$root.$keycloak.logout({ redirectUri: window.location.origin})
            });
          }
        }
      }
   ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to) => {
  if (to.meta.subject) {
    const ability = defineAbilityFor(store.getters['user/roles'])
    const canAccess = ability.can('view', to.meta.subject)
    if (!canAccess) return { name: 'unauthorized'}
  }
})

export default router
