export default [
  {
    component: 'CNavItem',
    name: 'Customers',
    to: '/customers',
    icon: 'cil-group',
  },
  {
    component: 'CNavItem',
    name: 'Accounts',
    to: '/accounts',
    icon: 'cil-sitemap',
  },
  {
    component: 'CNavItem',
    name: 'Ledgers',
    to: '/ledgers',
    icon: 'cil-spreadsheet',
  },
  {
    component: 'CNavTitle',
    name: 'Accounting',
  },
  {
    component: 'CNavItem',
    name: 'Status Log',
    to: '/accounting/AccountingStatusLog',
    icon: 'cil-list',
  },
  {
    component: 'CNavItem',
    name: 'Daily Rec CB',
    to: '/accounting/DailyReconciliation',
    icon: 'cil-check-circle',
  },
  {
    component: 'CNavItem',
    name: 'Daily Journal CB',
    to: '/accounting/DailyJournalCB',
    icon: 'cil-spreadsheet',
  },
  {
    component: 'CNavItem',
    name: 'Daily Rec CL',
    to: '/dailyReconciliationCL',
    icon: 'cil-check-circle',
  },
  {
    component: 'CNavItem',
    name: 'Daily Journal CL',
    to: '/dailyJournalCL',
    icon: 'cil-spreadsheet',
  },  
  {
    component: 'CNavItem',
    name: 'Ledgers Date',
    to: '/ledgersDate',
    icon: 'cil-calendar'
  },
  {
    component: 'CNavTitle',
    name: 'Settlements',
  },
  {
    component: 'CNavItem',
    name: 'Cashier',
    to: '/cashier',
    icon: 'cil-transfer'
  },
  {
    component: 'CNavItem',
    name: 'Positions',
    to: '/positions',
    icon: 'cil-aperture'
  },
  {
    component: 'CNavItem',
    name: 'Transactions',
    to: '/transactions',
    icon: 'cil-list-rich'
  },
  {
    component: 'CNavTitle',
    name: 'Reporting',
  },
  {
    component: 'CNavItem',
    name: 'Account Activity',
    icon: 'cil-bar-chart',
    to: '/reports/AccountActivity',
  },
  {
    component: 'CNavItem',
    name: '7-Day Activity',
    icon: 'cil-bar-chart',
    to: '/reports/SevenDayActivity',
  },
  {
    component: 'CNavItem',
    name: 'Cash Balance Summary',
    icon: 'cil-money',
    to: '/reports/C5/CashBalance',
  },
  /*
  {
    component: 'CNavItem',
    name: 'Customer Ledgers',
    icon: 'cil-money',
    to: '/reports/CustomerBalances',
  },
  */
  {
    component: 'CNavItem',
    name: 'Customer Balances',
    icon: 'cil-money',
    to: '/reports/CustomerBalances',
  },
  /*
  {
    component: 'CNavItem',
    name: 'Customer Statement',
    icon: 'cil-money',
    to: '/reports/CustomerBalances',
  },
  */
  {
    component: 'CNavItem',
    name: 'Credit Given',
    icon: 'cil-credit-card',
    to: '/reports/CreditGiven',
  },
  {
    component: 'CNavItem',
    name: 'Partner Activity',
    icon: 'cil-bar-chart',
    to: '/reports/PartnerActivity',
  },
  {
    component: 'CNavItem',
    name: 'C5 PPR',
    icon: 'cil-money',
    to: '/reports/C5PPR',
    subject: 'ppr_report'
  },
  {
    component: 'CNavItem',
    name: 'System View',
    icon: 'cil-share-boxed',
    to: '/reports/SystemLive',
  },
  {
    component: 'CNavItem',
    name: 'WDWN',
    icon: 'cil-grid',
    to: '/reports/WDWN',
  },
  {
    component: 'CNavTitle',
    name: 'System',
  },
  {
    component: 'CNavItem',
    name: 'FX Rates',
    to: '/fx/rates',
    icon: 'cil-chart-line'
  },
    {
    component: 'CNavItem',
    name: 'Email Delivery',
    to: '/system/email/customerStatement',
    icon: 'cil-envelope-closed'
  },
  /*
  {
    component: 'CNavItem',
    name: 'Scheduled Jobs',
    to: '/system/scheduledJobs',
    icon: 'cil-calendar',
    subject: 'roma_settings'
  },
  */
  {
    component: 'CNavItem',
    name: 'System Jobs',
    to: '/system/jobs',
    icon: 'cil-speedometer',
    subject: 'roma_settings'
  },
  {
    component: 'CNavItem',
    name: 'Recovery Tools',
    to: '/system/recovery',
    icon: 'cil-healing',
    subject: 'roma_settings'
  },
  {
    component: 'CNavGroup',
    name: 'Configuration',
    icon: 'cil-cog',
    subject: 'roma_config',
    items: [
      {
        component: 'CNavItem',
        name: 'Currency Config',
        to: '/settings/currencies',
        subject: 'roma_config',
      },
      {
        component: 'CNavItem',
        name: 'Partner Config',
        to: '/settings/partners',
        subject: 'roma_config',
      },
      {
        component: 'CNavItem', 
        name: 'Transaction Fees',
        to: '/settings/transactionFees',
        subject: 'roma_config',
      },
      {
        component: 'CNavItem',
        name: 'Settlement Terms',
        to: '/settings/settlementTerms',
        subject: 'roma_config',
      }
    ]
  },
]
