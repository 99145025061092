import api from '@/Api'
import mixins from '../../romaCommon.js'

const getDefaultState = () => {
  return {
    ledgerData: [],
    selectedDate: null,
    filterViewSuspended: false,
    filterViewClosed: false,
    filterHideZeroBalance: true,
    filterHideDormant: true,
    error: null,
  }
}

const state = getDefaultState();

const mutations = {
  setLedgerData(state, values) {
    state.ledgerData = values
  },
  setError(state,value) {
    state.error = value
  },
  clearError(state) {
    state.error = null
  },
  setSelectedDate(state, value) {
    state.selectedDate = value
  },
  setFilterViewSuspended(state, value) {
    state.filterViewSuspended = value
  },
  setFilterViewClosed(state, value) {
    state.filterViewClosed = value
  },
  setFilterHideZeroBalance(state, value) {
    state.filterHideZeroBalance = value
  },
  setFilterHideDormant(state, value) {
    state.filterHideDormant = value
  }
}

const getters = {
  ledgerData: state => {
    return state.ledgerData
  },
  selectedDate: state => {
    return state.selectedDate
  },
  filterViewSuspended: state => {
    return state.filterViewSuspended
  },
  filterViewClosed: state => {
    return state.filterViewClosed
  },
  filterHideZeroBalance: state => {
    return state.filterHideZeroBalance
  },
  filterHideDormant: state => {
    return state.filterHideDormant
  }
}

const actions = {
  loadLedgers({ commit, state }) {
    commit('clearError')
    const params =  new URLSearchParams( {
      weekEndDate: mixins.formatJsDate_localTZ(state.selectedDate),
      status: 'ACTIVE',
      hideZeroBalance: state.filterHideZeroBalance,
      hideDormant: state.filterHideDormant,
    })
    if (state.filterViewSuspended) {
      params.append('status', 'SUSPENDED')
    }
    if (state.filterViewClosed) { 
      params.append('status', 'CLOSED')
    }
    api.getPositions(params)
      .then( (response) => {
        console.log("ledgers loaded: ", response.data);
        commit('setLedgerData', response.data)
      }).catch(error => {
      console.error("failed to load ledgers:", error)
      commit('setError', { 
        message: "Failed to load ledgers",
        reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
      })
    })
  },
  setSelectedDate({ commit, dispatch }, range) {
    commit('setSelectedDate', range)
    dispatch('loadLedgers')
  },
  submitEmailBatch( {commit, state}, ledgerList ) {
    commit('clearError')
    var params = {
      statementDate: mixins.formatJsDate_localTZ(state.selectedDate),
      ledgerIds: ledgerList
    }
    return api.sendPositionStatementBatch(params)
      .then( (response) => {
        return response
      }).catch(error => {
        console.error("failed to submit email batch:", error)
        commit('setError', { 
          message: "Failed to submit send statement batch",
          reason: (error.response && error.response.status == 403) ? "Insufficient Permissions" : error.message
        })
     })
  },
  setFilterViewSuspended({commit, dispatch}, value) {
    commit('setFilterViewSuspended', value)
    dispatch('loadLedgers')
  },
  setFilterViewClosed({commit, dispatch}, value) {
    commit('setFilterViewClosed', value)
    dispatch('loadLedgers')
  },
  setFilterHideZeroBalance({commit,dispatch}, value) {
    commit('setFilterHideZeroBalance', value)
    dispatch('loadLedgers')
  },
  setFilterHideDormant({commit, dispatch}, value) {
    commit('setFilterHideDormant', value)
    dispatch('loadLedgers')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}