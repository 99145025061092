import {
  cilAddressBook,
  cilAperture,
  cilAudio,
  cilBank,
  cilBarChart,
  cilBell,
  cilBook,
  cilBrightness,
  cilBuilding,
  cilCalculator,
  cilCalendar,
  cilChartLine,
  cilCheckCircle,
  cilCircle,
  cilCog,
  cilCreditCard,
  cilDataTransferDown,
  cilDescription,
  cilEnvelopeClosed,
  cilEqualizer,
  cilFootball,
  cilGrid,
  cilGroup,
  cilHealing,
  cilInput,
  cilJustifyCenter,
  cilLayers,
  cilLink,
  cilList,
  cilListRich,
  cilLockLocked,
  cilMenu,
  cilMinus,
  cilMoney,
  cilNotes,
  cilPlus,
  cilReload,
  cilSettings,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeedometer,
  cilSpreadsheet,
  cilSitemap,
  cilSwapVertical,
  cilTask,
  cilTransfer,
  cilUser,
  cilUserX,
  cilWarning
} from '@coreui/icons'
export const iconsSet = Object.assign(
  {},
  {
    cilAddressBook,
    cilAperture,
    cilAudio,
    cilBank,
    cilBarChart,
    cilBell,
    cilBook,
    cilBrightness,
    cilBuilding,
    cilCalendar,
    cilCalculator,
    cilChartLine,
    cilCheckCircle,
    cilCircle,
    cilCreditCard,
    cilCog,
    cilDataTransferDown,
    cilDescription,
    cilEnvelopeClosed,
    cilEqualizer,
    cilFootball,
    cilGrid,
    cilGroup,
    cilHealing,
    cilInput,
    cilJustifyCenter,
    cilLayers,
    cilLink,
    cilList,
    cilListRich,
    cilLockLocked,
    cilMenu,
    cilMinus,
    cilMoney,
    cilNotes,
    cilPlus,
    cilReload,
    cilSettings,
    cilShieldAlt,
    cilShareBoxed,
    cilSpeedometer,
    cilSpreadsheet,
    cilSitemap,
    cilSwapVertical,
    cilTask,
    cilTransfer,
    cilUser,
    cilUserX,
    cilWarning,
  },
)
